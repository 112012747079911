import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'
import Home from '../screens/Home'
import About from '../screens/About'
import NotFound from '../screens/NotFound';
import Contact from '../screens/Contact';
import Services from '../screens/Services';
import Work from '../screens/Work';
import Cookies from './Cookies';

const AppNavigators = () => {
    return (
        <Router>
            <Header />

            <Routes>

                <Route
                    path='/'
                    element={<Home />}
                />

                <Route
                    path='/about'
                    element={<About />}
                />

                <Route
                    path='/services'
                    element={<Services />}
                />

                <Route
                    path='/work'
                    element={<Work />}
                />

                <Route
                    path='/contact'
                    element={<Contact />}
                />

                <Route
                    path='*'
                    element={<NotFound />}
                />

            </Routes>

            <Footer />
            <Cookies />
        </Router>
    )
}

export default AppNavigators;