import React, { useState } from 'react';
import './css/slider.css'; // Import your CSS file for styling
import Arrow from './images/arrows.png';

const Slider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => (prevIndex - 1));
    }
  };

  return (
    <div className="slider">
      
      <div className="slide" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {slides.map((slide, index) => (
          <div className="slide-content" key={index}>
            {slide}
          </div>
        ))}
      </div>

      <div className="slideBtn">
        <button
          className="slider-button prev-button"
          onClick={prevSlide}
          disabled={currentIndex === 0}
        >
          <img src={Arrow} alt="" className='arrowImg arrowLeft' />
        </button>

        <button className="slider-button next-button" onClick={nextSlide}>
          <img src={Arrow} alt="" className='arrowImg arrowRight' />
        </button>
      </div>
    </div>
  );
};

export default Slider;