import React, { useState, useEffect } from 'react';
import './css/cookies.css'; // Make sure to create this CSS file or include styles in your main CSS

const Cookies = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookiesAccepted = getCookie('cookiesAccepted');
    if (!cookiesAccepted) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    setCookie('cookiesAccepted', 'true', 365);
    setShowBanner(false);
  };

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  if (!showBanner) return null;

  return (
    <div id="cookieConsent">
      <div className="cookie-container">
        <p>We use cookies to ensure you get the best experience on our website. <a href="/privacy-policy">Learn more</a></p>
        <button id="acceptCookies" onClick={acceptCookies}>Accept Cookies</button>
      </div>
    </div>
  );
};

export default Cookies;