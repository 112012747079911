import './css/style.css';
import Logo from './images/Logo.png'
import { Link, NavLink } from 'react-router-dom'
import React, { useState, useEffect } from 'react';



const Header = () => {

    // const [showHeader, setShowHeader] = useState(true);
    // const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

    // const handleScroll = () => {
    //     const currentScrollPos = window.pageYOffset;
    //     const isVisible = prevScrollPos > currentScrollPos;

    //     setShowHeader(isVisible);
    //     setPrevScrollPos(currentScrollPos);
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, [prevScrollPos]);

    // const [openBar, setOpenBar] = useState(false);

    // const toggleSidebar  = () => {
    //     setOpenBar(!openBar);
    // }

    const [showHeader, setShowHeader] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [openBar, setOpenBar] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isVisible = prevScrollPos > currentScrollPos;

    setShowHeader(isVisible);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const toggleSidebar = () => {
    setOpenBar(!openBar);
    
    // Toggle class on the body element to disable/enable scroll
    if (!openBar) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  useEffect(() => {
    // Cleanup by removing the 'no-scroll' class if the component unmounts
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

    return (
        <header className={`Header ${showHeader ? 'header--visible' : 'header--hidden'}`}>
            <div className="container">
                <div className="subHeader">
                    <div className="leftHeader">
                        <Link to='/' className='linkTag'><img src={Logo} alt="logo" className='logo' /></Link>
                    </div>
                    <nav className="rightHeader">
                        <ul className='navMenu'>
                            <li className='font16'><NavLink className='linkTag18' activeClassName="active-link" to='/'>Home</NavLink></li>
                            <li className='font16'><NavLink className='linkTag18' activeClassName="active-link" to='/about'>About us</NavLink></li>
                            <li className='font16'><NavLink className='linkTag18' activeClassName="active-link" to='/services'>Services</NavLink></li>
                            <li className='font16'><NavLink className='linkTag18' activeClassName="active-link" to='/work'>Work</NavLink></li>
                            <li className='font16' style={{ marginRight: 0 }} ><NavLink className='linkTag18' activeClassName="active-link" to='/contact'>Contact us</NavLink></li>
                        </ul>
                    </nav>
                    <div className="sidebarIcon" onClick={toggleSidebar}>
                            <div className={openBar ? 'line line1 cros1' : 'line line1'}></div>
                            <div className={openBar ? 'line line2 cros2' : 'line line2'}></div>
                            <div className={openBar ? 'line line3 cros3' : 'line line3'}></div>    
                    </div>
                    <nav className={openBar ? 'rightSidebar' : 'rightSidebar openSideBar' }>
                        <ul className='navMenuOpen'>
                            <li className='font16Open'><NavLink className='linkTag18Open' activeClassName="active-link" to='/' onClick={() => setOpenBar(false)}>Home</NavLink></li>
                            <li className='font16Open'><NavLink className='linkTag18Open' activeClassName="active-link" to='/about' onClick={() => setOpenBar(false)}>About us</NavLink></li>
                            <li className='font16Open'><NavLink className='linkTag18Open' activeClassName="active-link" to='/services' onClick={() => setOpenBar(false)}>Services</NavLink></li>
                            <li className='font16Open'><NavLink className='linkTag18Open' activeClassName="active-link" to='/work' onClick={() => setOpenBar(false)}>Work</NavLink></li>
                            <li className='font16Open'><NavLink className='linkTag18Open' activeClassName="active-link" to='/contact' onClick={() => setOpenBar(false)}>Contact us</NavLink></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Header; 