import { Link } from 'react-router-dom';
import Logo from './images/Logo.png'
import './css/style.css';
import { FaTwitter, FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";


const Footer = () => {
    return (
        <div className="Footer">
            <div className="container">
                <div className="topFooter">
                    <div className="footerNav info">
                        <img src={Logo} alt="" className='footerLogo' />
                        <p className="footerContact">oxyenotech@gmail.com</p>
                        <p className="footerContact">+91 93168 14626</p>
                    </div>
                    <div className="footerNav links">
                        <h4 className='navTitle'>Link's</h4>
                        <Link to='/' className="navLink">Home</Link>
                        <Link to='/about' className="navLink">About</Link>
                        <Link to='/services' className="navLink">Services</Link>
                        <Link to='/work' className="navLink">Work</Link>
                        <Link to='/contact' className="navLink">Contact</Link>
                    </div>
                    <div className="footerNav services">
                        <h4 className='navTitle'>Services</h4>
                        <Link to='/services' className="navLink">UI/UX Design</Link>
                        <Link to='/services' className="navLink">Web Development</Link>
                        <Link to='/services' className="navLink">App Development</Link>
                        <Link to='/services' className="navLink">Software Development</Link>
                        <Link to='/services' className="navLink">Project Managment</Link>
                        <Link to='/services' className="navLink">Data Security</Link>
                    </div>
                    <div className="footerNav supports">
                        <h4 className='navTitle'>Supports</h4>
                        <Link className="navLink">Privacy Policy</Link>
                        <Link className="navLink">Term & Condition</Link>
                        <Link className="navLink">Cookies</Link>
                        <Link className="navLink">Help Support</Link>
                        <Link to='/contact' className="navLink">Contact us</Link>
                    </div>
                </div>
                <div className="lineDiv">
                    <div className="line"></div>
                </div>
                <div className="bottomFooter">
                    <p className="copyRight">© 2024 Oxyeno Tech All Right Reserved</p>
                    <div className="socialTag">
                        <Link to='/' className="socialLink">
                            <FaTwitter className='icon' />
                        </Link>
                        <Link to='https://www.instagram.com/oxyeno_tech/' className="socialLink">
                            <FaInstagram className='icon' />
                        </Link>
                        <Link to='/' className="socialLink">
                            <FaFacebook className='icon' />
                        </Link>
                        <Link to='https://www.linkedin.com/in/oxyeno-tech-695517315/' className="socialLink">
                            <FaLinkedin className='icon' />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;