import BgImage from './images/homeBG.jpeg'
import img1 from './images/1.jpg'
import img2 from './images/2.jpg'
import img3 from './images/3.jpg'
import img4 from './images/4.jpg'
import Design from './images/Design.png'
import Web from './images/Web.png'
import App from './images/App.png'
import Data from './images/Data.png'
import Manage from './images/Manage.png'
import Software from './images/Software.png'
import Arrow from './images/arrow.svg'
import './css/home.css';
import { Link } from 'react-router-dom';
import Slider from '../components/ArrowSlider'
import work1 from './images/work1.jpg';
import work2 from './images/work2.jpg';
import work3 from './images/work3.png';
import { BsArrowDownRight } from "react-icons/bs";



const slides = [
    // 1
    <div className='workSlide'>
        <Link className="workImages" to='/work'>
            <img src={work1} alt="" className='workImg' />
        </Link>
        <div className="workDes">
            <h4 className="workShowTitle"> ZikZak.com - Showcasing Innovative Architectural Excellence</h4>
            <h6 className='workType'>Website Development</h6>
            <p className="workShowCon">ZikZak.com is a meticulously crafted architecture portfolio website designed to exhibit the creative and technical prowess of modern architects. The site features a sleek and intuitive user interface, ensuring a seamless browsing experience. Utilizing cutting-edge web technologies such as MERN-Stack, ZikZak.com offers interactive project galleries, detailed case studies, and immersive 3D visualizations. Each project is highlighted with high-resolution images, comprehensive descriptions, and client testimonials, providing visitors with a thorough understanding of the architectural journey from concept to completion. ZikZak.com is not just a portfolio; it's a digital masterpiece that reflects the innovation and dedication of today's architectural visionaries.</p>
        </div>
    </div>,
    // 2
    <div className='workSlide'>
        <Link className="workImages" to='/work'>
            <img src={work2} alt="" className='workImg' />
        </Link>
        <div className="workDes">
            <h4 className="workShowTitle"> ZikZak.com - Showcasing Innovative Architectural Excellence</h4>
            <h6 className='workType'>Website Development</h6>
            <p className="workShowCon">ZikZak.com is a meticulously crafted architecture portfolio website designed to exhibit the creative and technical prowess of modern architects. The site features a sleek and intuitive user interface, ensuring a seamless browsing experience. Utilizing cutting-edge web technologies such as MERN-Stack, ZikZak.com offers interactive project galleries, detailed case studies, and immersive 3D visualizations. Each project is highlighted with high-resolution images, comprehensive descriptions, and client testimonials, providing visitors with a thorough understanding of the architectural journey from concept to completion. ZikZak.com is not just a portfolio; it's a digital masterpiece that reflects the innovation and dedication of today's architectural visionaries.</p>
        </div>
    </div>,
    // 3
    <div className='workSlide'>
        <Link className="workImages" to='/work'>
            <img src={work3} alt="" className='workImg' />
        </Link>
        <div className="workDes">
            <h4 className="workShowTitle"> ZikZak.com - Showcasing Innovative Architectural Excellence</h4>
            <h6 className='workType'>Website Development</h6>
            <p className="workShowCon">ZikZak.com is a meticulously crafted architecture portfolio website designed to exhibit the creative and technical prowess of modern architects. The site features a sleek and intuitive user interface, ensuring a seamless browsing experience. Utilizing cutting-edge web technologies such as MERN-Stack, ZikZak.com offers interactive project galleries, detailed case studies, and immersive 3D visualizations. Each project is highlighted with high-resolution images, comprehensive descriptions, and client testimonials, providing visitors with a thorough understanding of the architectural journey from concept to completion. ZikZak.com is not just a portfolio; it's a digital masterpiece that reflects the innovation and dedication of today's architectural visionaries.</p>
        </div>
    </div>,
    // 4
    <div className='workSlide'>
        <Link className="workImages" to='/work'>
            <img src={work1} alt="" className='workImg' />
        </Link>
        <div className="workDes">
            <h4 className="workShowTitle"> ZikZak.com - Showcasing Innovative Architectural Excellence</h4>
            <h6 className='workType'>Website Development</h6>
            <p className="workShowCon">ZikZak.com is a meticulously crafted architecture portfolio website designed to exhibit the creative and technical prowess of modern architects. The site features a sleek and intuitive user interface, ensuring a seamless browsing experience. Utilizing cutting-edge web technologies such as MERN-Stack, ZikZak.com offers interactive project galleries, detailed case studies, and immersive 3D visualizations. Each project is highlighted with high-resolution images, comprehensive descriptions, and client testimonials, providing visitors with a thorough understanding of the architectural journey from concept to completion. ZikZak.com is not just a portfolio; it's a digital masterpiece that reflects the innovation and dedication of today's architectural visionaries.</p>
        </div>
    </div>,

];

const Home = () => {

    return (
        <div className="home">
            <div className="heroSec">
                <img src={BgImage} alt="" className='bgImage' />
                <h3 className='coverText'>Transforming Technology into Business Growth</h3>
            </div>

            <div className="ourCompany">
                <div className="container">
                    <div className="textImgSec">
                        <div className="textSec">
                            <p className='aboutText'>ABOUT COMPANY</p>
                            <p className='middleText'>At <span className='primaryc'>Oxyeno Tech</span>, we've excelled in IT services for the past 3 years, delivering successful projects and ensuring client satisfaction through our dedication and commitment to excellence.</p>
                            <p className='lastText'>We prioritize attention to detail to ensure smooth and responsive system operations. At Oxyeno Tech, we provide the best in design, creating responsive and safe websites with secured databases, utilizing our cutting-edge Minified Technology to protect your data with robust, highly confidential firewalls.</p>
                            <Link className="contactBtn" to='/contact'>
                                <p className="subBtn">Contact us</p>
                            </Link>
                        </div>
                        <div className="imgSec">
                            <img src={img1} alt="img" className='img1' />
                            <img src={img2} alt="img" className='img2' />
                            <div className="imgColum">
                                <img src={img3} alt="img" className='img3' />
                                <img src={img4} alt="img" className='img4' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="servicesProvide">
                <div className="container">
                    <div className="servicesSec">
                        <h4 className='servicesText'>SERVICES WE DELIVER</h4>
                        <h3 className='servicesSubText'>Preparing for your success, we offer prominent <span className='primaryc'>IT solutions to help your business grow.</span></h3>

                        <div className="disServices">

                            {/* UI/UX Design */}
                            <Link className="box" to='/about'>
                                <div className="leftBox">
                                    <img src={Design} alt="" className='boxImg' />
                                </div>
                                <div className="rightBox">
                                    <h4 className="boxTitle">UI/UX Design</h4>
                                    <p className="boxContent">We specialize in creating user-centric interfaces that seamlessly merge intuitive functionality with captivating visual design, ensuring exceptional digital experiences for your users.</p>
                                    <div className="readArrow" >
                                        <p className='readMore'>Read More</p>
                                        <BsArrowDownRight className='arrowImg' />
                                    </div>
                                </div>
                            </Link>

                            {/* Website */}
                            <Link className="box" to='/about'>
                                <div className="leftBox">
                                    <img src={Web} alt="" className='boxImg' />
                                </div>
                                <div className="rightBox">
                                    <h4 className="boxTitle">Web Development</h4>
                                    <p className="boxContent">Building responsive, scalable websites tailored to meet your business objectives, ensuring optimal performance and user satisfaction across all devices.</p>
                                    <div className="readArrow" >
                                        <p className='readMore'>Read More</p>
                                        <BsArrowDownRight className='arrowImg' />
                                    </div>
                                </div>
                            </Link>

                            {/* App */}
                            <Link className="box" to='/about'>
                                <div className="leftBox">
                                    <img src={App} alt="" className='boxImg' />
                                </div>
                                <div className="rightBox">
                                    <h4 className="boxTitle">App Development</h4>
                                    <p className="boxContent">Creating innovative mobile applications for iOS and Android platforms that deliver engaging user experiences and drive business growth through cutting-edge technology solutions.</p>
                                    <div className="readArrow" >
                                        <p className='readMore'>Read More</p>
                                        <BsArrowDownRight className='arrowImg' />
                                    </div>
                                </div>
                            </Link>

                            {/* Data */}
                            <Link className="box" to='/about'>
                                <div className="leftBox">
                                    <img src={Data} alt="" className='boxImg' />
                                </div>
                                <div className="rightBox">
                                    <h4 className="boxTitle">Data Security</h4>
                                    <p className="boxContent">Implementing robust measures to safeguard sensitive information, ensuring compliance with industry standards and protecting your digital assets against threats.</p>
                                    <div className="readArrow" >
                                        <p className='readMore'>Read More</p>
                                        <BsArrowDownRight className='arrowImg' />
                                    </div>
                                </div>
                            </Link>

                            {/* Software */}
                            <Link className="box" to='/about'>
                                <div className="leftBox">
                                    <img src={Software} alt="" className='boxImg' />
                                </div>
                                <div className="rightBox">
                                    <h4 className="boxTitle">Software Development</h4>
                                    <p className="boxContent">Tailored solutions for enhanced efficiency and scalability, using cutting-edge tech to meet your specific business needs and drive growth.</p>
                                    <div className="readArrow" >
                                        <p className='readMore'>Read More</p>
                                        <BsArrowDownRight className='arrowImg' />
                                    </div>
                                </div>
                            </Link>

                            {/* Managment */}
                            <Link className="box" to='/about'>
                                <div className="leftBox">
                                    <img src={Manage} alt="" className='boxImg' />
                                </div>
                                <div className="rightBox">
                                    <h4 className="boxTitle">Project Management</h4>
                                    <p className="boxContent">Expertly orchestrating project lifecycles from inception to completion, ensuring streamlined processes, timely delivery, and successful outcomes aligned with your business objectives.</p>
                                    <div className="readArrow" >
                                        <p className='readMore'>Read More</p>
                                        <BsArrowDownRight className='arrowImg' />
                                    </div>
                                </div>
                            </Link>


                        </div>
                    </div>
                </div>
            </div>

            <div className="howWeWorks">
                <div className="container">
                    <div className="howWorks">
                        <div className="subTitleStep">
                            <div className="leftHow">
                                <h4 className='howTitle'>HOW WE WORKS</h4>
                                <h2 className='howSubTitle'>How Oxyeno Tech assist <span className='primaryc'>your business</span></h2>
                            </div>
                            <div className="stepCount">
                                <p className='step04'>STEPS</p>
                            </div>
                        </div>
                        <div className="steps">
                            {/* 1 */}
                            <div className="workDiv">
                                <div className="line"></div>
                                <div className="animProgress">
                                    <h6 className='circle'>1</h6>
                                    <div className="shadow1"></div>
                                    <div className="shadow2"></div>
                                </div>
                                <div className="workDes">
                                    <h4 className="workTitle">01. Discussion</h4>
                                    <p className="workSubTitle">We meet customers in set place to discuss the details about needs and demands before proposing a plan.</p>
                                    <Link className='lookMore' to='/about'>Look More</Link>
                                </div>
                            </div>
                            {/* 2 */}
                            <div className="workDiv">
                                <div className="line"></div>
                                <div className="animProgress">
                                    <h6 className='circle'>2</h6>
                                    <div className="shadow1"></div>
                                    <div className="shadow2"></div>
                                </div>
                                <div className="workDes">
                                    <h4 className="workTitle">02. Ideas & concepts</h4>
                                    <p className="workSubTitle">Our experts come up with all kinds of ideas and initiatives for delivering the best solutions for IT services chosen.</p>
                                    <Link className='lookMore' to='/about'>Look More</Link>
                                </div>
                            </div>
                            {/* 3 */}
                            <div className="workDiv">
                                <div className="line"></div>
                                <div className="animProgress">
                                    <h6 className='circle'>3</h6>
                                    <div className="shadow1"></div>
                                    <div className="shadow2"></div>
                                </div>
                                <div className="workDes">
                                    <h4 className="workTitle">03. Testing & Trying</h4>
                                    <p className="workSubTitle">After agreeing on the ideas and plans, we will conduct as scheduled and give comments on the results & adaptations.</p>
                                    <Link className='lookMore' to='/about'>Look More</Link>
                                </div>
                            </div>
                            {/* 4 */}
                            <div className="workDiv">
                                <div className="animProgress">
                                    <h6 className='circle'>4</h6>
                                    <div className="shadow1"></div>
                                    <div className="shadow2"></div>
                                </div>
                                <div className="workDes">
                                    <h4 className="workTitle">04. Execute & install</h4>
                                    <p className="workSubTitle">Once the final plan is approved, everything will be conducted according to the agreed contract.</p>
                                    <Link className='lookMore' to='/about'>Look More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='weRun'>
                <div className="container">
                    <div className="textBtn">
                        <h4 className='weRunTitle'>We run all kinds of IT services that vow your <span className='primaryc'>success</span></h4>
                        <div className="btn">
                            <Link className='getInfo' to='/about'>Get Info</Link>
                            <Link className='contact' to='/contact'>Contact us</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="workShow">
                <div className="container">
                    <h4 className="workshowTitle">CASE STUDIES</h4>
                    <p className='workshowsubTitle'>Proud projects that <span className='primaryc'> make us stand out</span></p>
                    <Slider slides={slides} />
                </div>
            </div>
        </div>
    )
}

export default Home;